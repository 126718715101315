<template>
	<div class="about_pc padding_120" style="padding-bottom: 60px">
		<div class="about_img">
			<img
				src="https://res.metaera.hk/resources/assets/images/aboutus/a18.png"
				alt=""
				class=""
			/>
		</div>
		<div class="about_content">
			<div class="about_content_left">
				<div class="about_content_title">
					<!-- <img src="https://res.metaera.hk/resources/assets/images/aboutus/a1.png" /> -->
					<span>关于MetaEra</span>
				</div>
				<div class="about_content_text">
					<div>
						MetaEra 是 Web 3.0
						行业领先的资讯平台和品牌与增长专家。在全球各地区利用全方位优势资源，为您的品牌管理和业务增长提供创意型解决方案和定制化服务。
					</div>
					<div>MetaEra将为全球客户提供以下服务：</div>
					<div class="about_content_text_div"
						><p class="about_content_text_p">媒体策略与传播</p>
						<p class="about_content_text_p">内容创作</p></div
					>
					<div class="about_content_text_div"
						><p class="about_content_text_p">KOL营销</p>
						<p class="about_content_text_p">社交媒体与社区增长</p></div
					>
					<div class="about_content_text_div"
						><p class="about_content_text_p">线下活动</p>
						<p class="about_content_text_p">线上活动与AMA</p></div
					>
					<div class="about_content_text_div"
						><p class="about_content_text_p">研报与市场分析</p>
						<p class="about_content_text_p">增长策略与数据分析</p></div
					>
					<div class="about_content_text_div"
						><p class="about_content_text_p">公共事务</p></div
					>
				</div>
				<div class="about_bottom">
					<div class="about_content_title">
						<!-- <img src="https://res.metaera.hk/resources/assets/images/aboutus/a9.png" /> -->
						<span>Logo下载</span>
					</div>
					<div class="about_bottom_1 about_bottom_1_pc">
						<div
							class="about_bottom_1_item about_bottom_wid"
							@click="downloadFile('file/ME-logo.svg')"
						>
							<img
								class="about_bottom_1_item_img1"
								src="https://res.metaera.hk/resources/assets/images/aboutus/a11.png"
							/>
							<p class="flex-column">
								<img
									class="about_bottom_1_item_img2"
									src="https://res.metaera.hk/resources/assets/images/aboutus/down_w.png"
								/>
								<span>MetaEra Logo.SVG</span>
							</p>
						</div>
						<div
							class="about_bottom_1_item"
							@click="downloadFile('file/ME-logo.ai')"
						>
							<img
								class="about_bottom_1_item_img1"
								src="https://res.metaera.hk/resources/assets/images/aboutus/a6.png"
							/>
							<p class="flex-column">
								<img
									class="about_bottom_1_item_img2"
									src="https://res.metaera.hk/resources/assets/images/aboutus/down_w.png"
								/>
								<span>MetaEra Logo.AI</span>
							</p>
						</div>
						<div
							class="about_bottom_1_item"
							@click="downloadFile('file/ME-logo.eps')"
						>
							<img
								class="about_bottom_1_item_img1"
								src="https://res.metaera.hk/resources/assets/images/aboutus/a7.png"
							/>
							<p class="flex-column">
								<img
									class="about_bottom_1_item_img2"
									src="https://res.metaera.hk/resources/assets/images/aboutus/down_w.png"
								/>
								<span>MetaEra Logo.EPS</span>
							</p>
						</div>
						<div
							class="about_bottom_1_item about_bottom_wid"
							@click="downloadFile('file/ME-logo-黑色.png')"
						>
							<img
								class="about_bottom_1_item_img1"
								src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
							/>
							<p class="flex-column">
								<img
									class="about_bottom_1_item_img2"
									src="https://res.metaera.hk/resources/assets/images/aboutus/down_w.png"
								/>
								<span>MetaEra Logo.PNG(黑色)</span>
							</p>
						</div>
						<div
							class="about_bottom_1_item"
							@click="downloadFile('file/ME-logo-反白.png')"
						>
							<img
								class="about_bottom_1_item_img1"
								src="https://res.metaera.hk/resources/assets/images/aboutus/a10.png"
							/>
							<p class="flex-column"
								><img
									class="about_bottom_1_item_img2"
									src="https://res.metaera.hk/resources/assets/images/aboutus/down_w.png"
								/>
								<span>MetaEra Logo.PNG(白色)</span></p
							>
						</div>
					</div>
				</div>
			</div>
			<div class="about_content_right">
				<div class="about_content_title">
					<!-- <img src="https://res.metaera.hk/resources/assets/images/aboutus/a1.png" /> -->
					<span>联系我们</span>
				</div>
				<div class="about_content_box">
					<div class="about_content_box_content">
						<img
							src="https://res.metaera.hk/resources/assets/images/aboutus/a3_w1.png"
						/>
						<span>WeChat：MetaEra_Media</span>
					</div>
					<div class="about_content_box_content">
						<img
							src="https://res.metaera.hk/resources/assets/images/aboutus/a15_w.png"
						/>
						<span>Telegram：MetaEra_Media</span>
					</div>
					<div
						class="about_content_box_content pointer"
						@click="sendEmail('event@metaera.hk')"
					>
						<img
							src="https://res.metaera.hk/resources/assets/images/aboutus/a8_w.png"
						/>
						<span>Email: event@metaera.hk </span>
					</div>
					<div class="about_content_box_content">
						<img
							src="https://res.metaera.hk/resources/assets/images/aboutus/a15_w.png"
						/>
						<span>Telegram: MetaEraEvent</span>
					</div>
				</div>
				<div class="about_content_bottom">
					<div class="about_content_box_title">MetaEra社區：</div>
					<div class="about_content_bottom_1">
						<div
							class="about_content_bottom_1_item pointer"
							@click="showUrl('https://twitter.com/MetaEraHK')"
						>
							<img
								src="https://res.metaera.hk/resources/assets/images/aboutus/a5.png"
							/>
							<span>Twitter</span>
						</div>
						<div
							class="about_content_bottom_1_item pointer"
							@click="showUrl('https://twitter.com/MetaEraCN')"
						>
							<img
								src="https://res.metaera.hk/resources/assets/images/aboutus/a5.png"
							/>
							<span>Twitter中文</span>
						</div>
						<div
							class="about_content_bottom_1_item pointer"
							@click="showUrl('https://t.me/MetaEraHK')"
						>
							<img
								src="https://res.metaera.hk/resources/assets/images/aboutus/a12.png"
							/>
							<span>Telegram</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	methods: {
		downloadFile(fileName) {
			const fileUrl = "/path/to/" + fileName; // 文件的URL地址
			const link = document.createElement("a");
			link.href = fileName;
			link.setAttribute("download", fileName);
			link.click();
		},
		sendEmail(url) {
			const email = url;
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		showUrl(url) {
			window.open(url, "");
		},
	},
};
</script>
<style scoped>
.about_pc {
	padding-top: 30px;
	color: #fff;
}
.about_img {
	padding-top: 0px;
	width: 100%;
	height: auto;
}
.about_img img {
	width: 100%;
	height: auto;
	border-radius: 10px;
}

.about_content {
	margin-top: clamp(20px, calc(100vw * 42 / 1440), 42px);
	display: flex;
	justify-content: space-between;
}

.about_content_left {
	width: clamp(600px, calc(100vw * 730 / 1440), 730px);
}
.about_content_right {
	margin-top: 0px;
	width: clamp(200px, calc(100vw * 400 / 1440), 400px);
}
.about_content_title {
	display: flex;
	margin-bottom: calc(100vw * 30 / 1440);
	padding-bottom: calc(100vw * 30 / 1440);
	border-bottom: 1px solid rgba(225, 227, 241, 0.5);
}
.about_content_title img {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	opacity: 1;

	/* 333 */
	background: #333333;
}
.about_content_title span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(20px, calc(100vw * 24 / 1440), 24px);
	font-weight: bold;
	line-height: 1;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}

.about_content_text {
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
	display: block;
}
.about_content_text div {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 16 / 1440), 16px);
	font-weight: normal;
	line-height: 30px;
}
.about_content_text p {
	margin-top: 10px;
	width: 50%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 16 / 1440), 16px);
	font-weight: 500;
	line-height: 22px;
}
.about_content_text_div {
	display: flex;
}
.about_content_text_p {
	margin-left: 00px;
}

.about_content_slogn {
	margin-top: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: 600;
	line-height: 26px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}

.about_content_box {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.about_content_box_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(20px, calc(100vw * 24 / 1440), 24px);
	font-weight: bold;
	line-height: 1;
	text-align: center;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
	padding-bottom: clamp(20px, calc(100vw * 30 / 1440), 30px);
	border-bottom: 1px solid rgba(225, 227, 241, 0.5);
}
.about_content_box_content {
	display: flex;
	margin-top: 25px;
}

.about_content_box_content img {
	width: clamp(18px, calc(100vw * 28 / 1440), 28px);
	height: clamp(18px, calc(100vw * 28 / 1440), 28px);
	opacity: 1;
}

.about_content_box_content span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 16 / 1440), 16px);
	font-weight: normal;
	line-height: 1;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}

.about_content_bottom_1 {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
}
.about_content_bottom_1_item {
	display: flex;
}
.about_content_bottom_1_item img {
	width: 20px;
	height: 20px;
	border-radius: 2px;
	opacity: 1;

	/* 333 */
	background: #333333;
}

.about_content_bottom_1_item span {
	margin-left: 10px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 16 / 1440), 16px);
	font-weight: normal;
	line-height: 1;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
}
.about_content_bottom {
	margin-top: 50px;
}
.about_bottom {
	margin-top: 80px;
	margin-bottom: 100px;
}
.about_bottom_1 {
	margin-top: 35px;
	display: flex;
}

.about_bottom_1_item {
	display: flex;
	align-items: center;
}

.about_bottom_wid {
	width: clamp(200px, calc(100vw * 255 / 1440), 255px);
}

.about_bottom_1_item span {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: clamp(10px, calc(100vw * 16 / 1440), 16px);
	font-weight: normal;
	line-height: 1;
	display: flex;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #fff;
	margin-top: 10px;
}

.about_bottom_1_item_img1 {
	width: clamp(40px, calc(100vw * 50 / 1440), 50px);
	height: clamp(40px, calc(100vw * 50 / 1440), 50px);
	margin-right: clamp(10px, calc(100vw * 15 / 1440), 15px);
	opacity: 1;
}
.flex-column {
	display: flex;
	flex-direction: column;
}
.about_bottom_1_item_img2 {
	width: 14px;
	height: 15px;
}

.about_bottom_1_pc {
	display: flex;
	flex-wrap: wrap;
	row-gap: calc(100vw * 20 / 1440);
	column-gap: calc(100vw * 25 / 1440);
}
</style>
